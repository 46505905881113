<template>
  <div>
    <vue-element-loading
      :active="appLoading"
      :is-full-screen="true"
      background-color="#FFFFFF"
      color="#005f32"
      spinner="bar-fade-scale"
    />
    <ServerError v-if="ServerError" />
    <v-snackbar v-model="showsnackbar" color="black" right>
      <v-layout wrap justify-center>
        <v-flex text-left class="align-self-center">{{ msg }}</v-flex>
        <v-flex text-right>
          <v-btn small :ripple="false" text @click="showsnackbar = false">
            <v-icon style="color: white">mdi-close</v-icon>
          </v-btn>
        </v-flex>
      </v-layout>
    </v-snackbar>
    <v-layout wrap>
      <v-flex>
        <v-layout wrap justify-start>
          <v-flex xs12 pa-4 text-left>
            <span style="font-family: poppinssemibold">EMPLOYEE DETAILS</span>
            <v-progress-linear
              :color="appColor"
              rounded
              value="100"
              height="2px"
            ></v-progress-linear>
          </v-flex>

          <v-flex xs12 px-2>
            <v-card>
              <v-layout
                wrap
                pa-3
                text-left
                style="font-family: poppinsmedium; font-size: 12px"
              >
                <v-flex xs12 md6>
                  <v-layout wrap text-left v-if="userData.eid">
                    <v-flex xs4>
                      <v-icon small> mdi-account</v-icon>&nbsp;
                      <span><b>Name</b> </span>
                    </v-flex>
                    <v-flex xs8>
                      <span v-if="userData.eid.name">{{
                        userData.eid.name
                      }}</span>
                    </v-flex>
                    <!-- <v-flex xs4 py-2>
                      <v-icon small> mdi-email</v-icon>&nbsp;
                      <span><b>E mail</b> </span>
                    </v-flex> -->
                    <!-- <v-flex xs8 py-2>
                      <span v-if="userData.eid.email">{{
                        userData.eid.email
                      }}</span> </v-flex
                    ><v-flex xs4>
                      <v-icon small> mdi-phone</v-icon>&nbsp;
                      <span><b>Phone</b> </span>
                    </v-flex>
                    <v-flex xs8>
                      <span v-if="userData.eid.mobile">{{
                        userData.eid.mobile
                      }}</span>
                    </v-flex> -->
                  </v-layout>
                </v-flex>
                <v-divider vertical> </v-divider>
                <v-flex xs12 md12 pl-5 fill-height>
                  <v-layout wrap >
                    <!-- <v-flex xs4>
                      <span><b>Total Project Splits</b> </span>
                    </v-flex>
                    <v-flex xs8 v-if="targets">
                      <h2> - {{ targets.length }} </h2>
                    </v-flex> -->
                    <v-flex xs12>
                      <span><b>Organisations :</b> </span>
                    
                      <span v-for="(item, i) in totalOrg" :key="i">
                        ,{{ item }}
                      </span>
                    </v-flex>
                    
                  </v-layout>
                </v-flex>
              </v-layout>
            </v-card>
          </v-flex>

          <template v-if="targets.length > 0">
            <v-flex xs12 px-2 pt-3 v-for="(item, i) in targets" :key="i">
              <v-card style="font-family: poppinsmedium; font-size: 12px">
                <v-layout wrap>
                  <v-flex
                    xs12
                    align-self-center
                    style="background-color: rgb(230, 230, 230)"
                  >
                    <v-layout wrap pr-lg-3 pa-2>
                      <v-flex
                        xs12
                        text-left
                        class="xlarge"
                        style="font-family: poppinsbold"
                      >
                        {{ item.targetid.projectid.projectName }}
                      </v-flex>
                      <v-flex xs6 text-left>
                        <span> Financial Year :</span
                        ><span style="color: black; font-size: 16px">{{
                          item.targetid.fyear.fyear
                        }}</span>
                      </v-flex>
                      <v-flex xs6 text-right v-if="userType == 'SuperAdmin'">
                        <v-dialog
                          :retain-focus="false"
                          v-model="item.dialogSplit"
                          max-width="50%"
                          :key="item.dialogSplit"
                        >
                          <template v-slot:activator="{ on, attrs }">
                            <v-btn
                              style="font-family: poppinsmedium"
                              x-small
                              dark
                              class="rounded-xl text-none"
                              color="#5F4B8BFF"
                              v-bind="attrs"
                              v-on="on"
                              @click="(B = item), CalculateBalance(item)"
                            >
                              &nbsp; Add Organisation
                              <v-icon x-small>mdi-plus</v-icon>
                            </v-btn>
                          </template>
                          <v-card>
                            <v-form v-model="addcat" ref="addcat">
                              <v-layout wrap>
                                <v-flex
                                  xs12
                                  style="background-color: #5f4b8bff"
                                >
                                  <span
                                    style="
                                      color: #ffffff;
                                      font-size: 20px;
                                      font-family: poppinsbold;
                                      padding: 2px;
                                    "
                                    class="RB"
                                    >ADD SPLIT TARGET</span
                                  >
                                </v-flex>
                              </v-layout>
                              <v-layout wrap pa-12 justify-center fill-height>
                                <v-flex xs12 text-right>
                                  <span>Total Split Target :</span>
                                  <span style="font-family: poppinsbold">
                                    Rs. {{ item.amount }}
                                  </span>
                                  <br />
                                  <span>Remaining Organisation Split :</span>
                                  <span style="font-family: poppinsbold">
                                    Rs.{{ item.amount - totalOrgAmt }}
                                  </span>

                                  <br />
                                </v-flex>
                                <v-flex xs12 text-left py-2>
                                  <v-divider></v-divider>
                                </v-flex>

                                <v-flex
                                  xs12
                                  sm6
                                  align-self-center
                                  text-left
                                  pa-2
                                >
                                  <span>Organisation*</span>
                                  <br />

                                  <v-select
                                    v-model="orgId"
                                    :items="organisationList"
                                    item-text="name"
                                    item-value="_id"
                                    item-color="#8d8d8d"
                                    placeholder="Organisation"
                                    outlined
                                    hide-details
                                    required
                                    dense
                                  >
                                  </v-select>
                                </v-flex>
                                <v-flex
                                  xs12
                                  sm6
                                  align-self-center
                                  text-left
                                  pa-2
                                >
                                  <span>Target Amount(INR)*</span>
                                  <br />
                                  <v-text-field
                                    v-model="amount"
                                    type="Number"
                                    placeholder="Target Amount (INR)"
                                    :rules="[rules.required]"
                                    required
                                    outlined
                                    hide-details
                                    dense
                                  ></v-text-field>
                                </v-flex>

                                <v-flex xs12 align-self-center text-right>
                                  <v-btn
                                    color="#CE4A7EFF"
                                    style="font-family: poppinsmedium"
                                    small
                                    tile
                                    dark
                                    class="rounded-xl"
                                    :disabled="!addcat"
                                    @click="
                                      addSplit(item), (item.dialogSplit = false)
                                    "
                                  >
                                    <span style="color: #ffffff !important"
                                      >Submit</span
                                    ></v-btn
                                  >
                                </v-flex>
                              </v-layout>
                            </v-form>
                          </v-card>
                        </v-dialog>
                      </v-flex>

                      <v-flex xs12 text-left>
                        <span> Target :</span
                        ><span style="color: black; font-size: 16px">
                          {{ item.amount }}&nbsp;(INR)
                        </span></v-flex
                      >
                    </v-layout>
                  </v-flex>

                  <v-flex xs12 pt-3>
                    <template v-if="item.split.length > 0">
                      <v-simple-table dense>
                        <template v-slot:default>
                          <thead>
                            <tr>
                              <th class="text-left">Organisation</th>
                              <th class="text-left">Split (₹)</th>
                              <!-- <th class="text-left">Already Raised (₹)</th> -->
                              <th class="text-left">
                                <span style="color: green">Amount Received (₹)</span>
                              </th>
                              <th class="text-left">
                                <span style="color: #EDD94C">Committed and Confirmed (₹)</span>
                              </th>
                              <th class="text-left">
                                <span style="color: blue"
                                  >Committed but not Confirmed (₹)</span
                                >
                              </th>
                              <th class="text-left">
                                <span style="color: red">Amount Received (₹)</span>
                              </th>
                              <th class="text-left">
                                <span style="color: red">&nbsp;Add Split<br />
                                (Next Year)
                                </span>
                              </th>
                              <th class="text-center">Add Split</th>
                              <th
                                class="text-right pr-5"
                                v-if="userType == 'SuperAdmin'"
                              >
                                Delete
                              </th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr v-for="(item2, i) in item.split" :key="i">
                              <td class="text-left" style="font-size: 12px">
                                <router-link
                                  :to="
                                    '/SplitDetaildView?id=' +
                                    item2._id +
                                    '&name=' +
                                    item2.orgId.name
                                  "
                                >
                                  <span style="color: blue">{{
                                    item2.orgId.name
                                  }}</span>
                                </router-link>
                              </td>

                              <td class="text-left" style="font-size: 12px">
                                {{ item2.amount }}
                              </td>
                              <!-- <td class="text-left" style="font-size: 12px">
                                {{ item2.alreadyraised }}
                              </td> -->
                              <td class="text-left" style="font-size: 12px">
                                {{ item2.amountAchievedAmount }}
                              </td>

                              <td class="text-left" style="font-size: 12px">
                                {{ item2.committedAmount }}
                              </td>
                              <td class="text-left" style="font-size: 12px">
                                {{ item2.notconfirmedAmount }}
                              </td>
                              <td class="text-left" style="font-size: 12px">
                                {{ item2.toberaisedAmount }}
                              </td>

                              <td
                                class="text-left"
                                style="font-size: 12px"
                                v-if="item2.splitSettingsExists == false"
                              >
                                <v-btn
                                  style="font-family: poppinsmedium"
                                  block
                                  x-small
                                  class="rounded-xl text-none text-left"
                                  @click="
                                    splitTarget(item2), (splitId = item2._id)
                                  "
                                >
                                  Add <v-icon x-small>mdi-plus</v-icon>
                                </v-btn>
                              </td>
                              <td v-else>
                                <span style="font-size: 12px">
                                  {{ item2.splitSettins.amount }} <br
                                /></span>
                                <v-btn
                                  style="font-family: poppinsmedium"
                                  block
                                  x-small
                                  class="rounded-xl text-none text-left"
                                  @click="
                                    (editsplit = true),
                                      (curid = item2.splitSettins)
                                  "
                                >
                                  Edit <v-icon x-small>mdi-pencil</v-icon>
                                </v-btn>
                                <v-icon
                                  @click="
                                    (deletedialog2 = true),
                                      (curid = item2.splitSettins)
                                  "
                                  small
                                  color="error"
                                  >mdi-delete</v-icon
                                ><br />
                                <span style="font-size: 12px">{{
                                  item2.splitSettins.fyear.fyear
                                }}</span>
                              </td>
                              <td class="text-left" style="font-size: 12px">
                                <v-btn
                                  style="font-family: poppinsmedium"
                                  block
                                  x-small
                                  class="rounded-xl text-none text-left"
                                  @click="
                                    openTarget(item), (splitID = item2._id)
                                  "
                                >
                                  Add <v-icon x-small>mdi-plus</v-icon>
                                </v-btn>
                              </td>
                              <td class="text-right" style="font-size: 12px">
                                <v-icon
                                  small
                                  v-if="userType == 'SuperAdmin'"
                                  @click="deleteDial(item._id)"
                                  color="error"
                                  >mdi-delete</v-icon
                                >
                              </td>
                            </tr>
                          </tbody>
                        </template>
                      </v-simple-table>
                    </template>

                    <template v-else>
                      <v-layout wrap justify-center text-center fill-height>
                        <v-flex xs12 align-self-center>
                          <span style="color: red">
                            No Split Assigned yet...!
                          </span>
                        </v-flex>
                      </v-layout>
                    </template>
                  </v-flex>
                  <v-flex xs12 text-right py-1>
                    <v-btn
                      v-if="userType == 'SuperAdmin'"
                      @click="
                        (DeleteTargetid = item._id), (DeleteTargetDialog = true)
                      "
                      color="red"
                      style="font-family: poppinsmedium"
                      small
                      text
                      dark
                      class="rounded-xl text-none"
                    >
                      Delete
                    </v-btn>
                  </v-flex>
                </v-layout>
              </v-card>
            </v-flex>
          </template>
          <template v-else>
            <v-layout wrap>
              <v-flex
                xs12
                pt-4
                text-center
                style="font-family: poppinssemibold"
              >
                <span> No Data....!</span>
              </v-flex>
            </v-layout>
          </template>
        </v-layout>
        <!-- </v-card> -->
      </v-flex>
    </v-layout>
    <v-dialog width="400px" v-model="SplitDeleteDialog">
      <v-card width="400px">
        <v-layout wrap justify-center pa-4>
          <v-flex xs12 dark dense pb-4>
            <b> Are you sure you want to delete this split ....? </b>
          </v-flex>
          <v-flex xs12 text-right>
            <v-btn dark x-small color="error" @click="SplitDeleteDialog = false"
              >Cancel</v-btn
            >
            <v-btn
              dark
              x-small
              color="primary"
              class="ml-2"
              @click="DeleteSplit()"
            >
              Ok</v-btn
            >
          </v-flex>
        </v-layout>
      </v-card>
    </v-dialog>

    <v-dialog width="400px" v-model="DeleteTargetDialog">
      <v-card width="400px">
        <v-layout wrap justify-center pa-4>
          <v-flex xs12 dark dense pb-4>
            <b> Are you sure you want to delete this Project split ....? </b>
          </v-flex>
          <v-flex xs12 text-right>
            <v-btn
              dark
              x-small
              color="error"
              @click="DeleteTargetDialog = false"
              >Cancel</v-btn
            >
            <v-btn
              dark
              x-small
              color="primary"
              class="ml-2"
              @click="deleteTarget()"
            >
              Ok</v-btn
            >
          </v-flex>
        </v-layout>
      </v-card>
    </v-dialog>
    <v-dialog v-model="spliofSplitDial" width="300px">
      <v-card color="black" width="300px">
        <v-layout wrap pt-5 pr-5 pl-5>
          <v-flex xs12>
            <v-select
              outlined
              dark
              dense
              label="Types"
              :items="values"
              v-model="assignType"
            >
            </v-select>
          </v-flex>
          <v-flex xs12>
            <v-text-field outlined dark dense label="amount" v-model="MyAmount">
            </v-text-field>
          </v-flex>
          <v-flex xs12>
            <v-text-field outlined dark dense label="Notes" v-model="note">
            </v-text-field>
          </v-flex>
          <v-flex
            xs12
            v-if="
              assignType == 'already received' ||
              assignType == 'committed and confirmed'
            "
          >
            <v-menu
              ref="menu4"
              v-model="menu4"
              :close-on-content-click="false"
              :return-value.sync="FundApprovedDate"
              transition="scale-transition"
              offset-y
              min-width="290px"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  outlined
                  v-model="FundApprovedDate"
                  :rules="[rules.required]"
                  label="Select Date"
                  append-icon="mdi-calendar"
                  dense
                  dark
                  readonly
                  v-bind="attrs"
                  v-on="on"
                ></v-text-field>
              </template>
              <v-date-picker v-model="FundApprovedDate" no-title scrollable>
                <v-spacer></v-spacer>
                <v-btn text color="primary" @click="menu4 = false">
                  Cancel
                </v-btn>
                <v-btn
                  text
                  color="primary"
                  @click="$refs.menu4.save(FundApprovedDate)"
                >
                  OK
                </v-btn>
              </v-date-picker>
            </v-menu>
          </v-flex>
        </v-layout>
        <v-layout wrap pa-2>
          <v-flex xs12>
            <v-btn @click="addToRaiseFund"> save </v-btn>
          </v-flex>
        </v-layout>
      </v-card>
    </v-dialog>
    <v-dialog v-model="editsplit" width="300px">
      <v-card color="black" width="300px">
        <v-layout wrap pt-5 pr-5 pl-5>
          <v-flex xs12>
            <v-text-field
              outlined
              dark
              dense
              label="amount"
              v-model="curid.amount"
            >
            </v-text-field>
          </v-flex>
          <v-flex xs12> </v-flex>
        </v-layout>
        <v-layout wrap pa-2>
          <v-flex xs12>
            <v-btn @click="editsplitdetails()"> save</v-btn>
          </v-flex>
        </v-layout>
      </v-card>
    </v-dialog>

    <v-dialog v-model="split2" width="300px">
      <v-card color="black" width="300px">
        <v-layout wrap pt-5 pr-5 pl-5>
          <v-flex xs12>
            <v-select
              outlined
              dark
              dense
              item-text="fyear"
              label="Financial Year"
              item-value="_id"
              :items="years.slice(1.4)"
              v-model="financialYear"
            >
            </v-select>
          </v-flex>
          <v-flex xs12>
            <v-text-field outlined dark dense label="amount" v-model="amount">
            </v-text-field>
          </v-flex>

          <v-flex xs12> </v-flex>
        </v-layout>
        <v-layout wrap pa-2>
          <v-flex xs12>
            <v-btn @click="addToRaiseFund2"> save</v-btn>
          </v-flex>
        </v-layout>
      </v-card>
    </v-dialog>
    <v-dialog v-model="deletedialog2" max-width="600px">
      <v-card>
        <v-card-title>Are you sure you want to delete this Split?</v-card-title>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="blue darken-1" text @click="deletedialog2 = false"
            >Cancel</v-btn
          >
          <v-btn color="blue darken-1" text @click="deletesplitdetails()"
            >OK</v-btn
          >
          <v-spacer></v-spacer>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog v-model="dialog2" max-width="400px">
      <v-card color="black">
        <v-layout wrap justify-center>
          <v-flex xs12 v-for="(item, i) in splitdetails" :key="i">
            <v-layout
              wrap
              pt-5
              pr-5
              pl-5
              v-for="(list, j) in item.split"
              :key="j"
            >
              <v-flex xs12 v-if="list.splitSettins">
                <span
                  style="
                    font-family: opensanssemibold;
                    font-size: 16px;
                    color: white;
                    letter-spacing: 1px;
                    cursor: pointer;
                  "
                  >{{ list.splitSettins.amount }}</span
                >
              </v-flex>
            </v-layout>
          </v-flex>
        </v-layout>
        <v-layout wrap pa-2>
          <v-flex xs12>
            <v-btn @click="addToRaiseFund"> save</v-btn>
          </v-flex>
        </v-layout>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
import axios from "axios";
export default {
  data() {
    return {
      B: 0,
      totalOrgAmt: 0,
      totalOrg: [],
      menu4: false,
      curid: [],
      FundApprovedDate: null,
      splitDeleteId: null,
      SplitDeleteDialog: null,
      dialog2: false,
      note: null,
      DeleteTargetDialog: false,
      DeleteTargetid: null,
      splitID: null,
      deletedialog2: false,
      deletedialog: false,
      splitId: null,
      MyAmount: 0,
      values: [
        "already received",
        "committed and confirmed",
        "committed but not confirmed",
        "to be raised",
      ],
      assignType: null,
      financialYear: null,
      target: false,
      spliofSplitDial: false,
      split2: false,
      splitSettingsExists: false,
      targetData: [],
      targetData2: [],
      userData: [],
      splitdetails: [],
      appLoading: false,
      showsnackbar: false,
      ServerError: false,
      msg: null,
      pages: 0,
      financialyear: null,
      totalTarget: null,
      addcat: false,
      addorg: false,
      rules: {
        required: (value) => !!value || "Required.",
      },
      dialog: false,
      dialogSplit: false,
      editdialog: false,
      editsplit: false,
      editingitem: [],
      splitArray: [],
      split: [],
      years: [],
      targets: [],

      amount: 0,
      orgId: null,
      organisationList: [],
      raiseAmount: {
        id: null,
        amount: null,
      },
      projects: [],
    };
  },
  components: {},
  mounted() {
    this.getOrganization();
    this.getData();
    this.getYear();
    this.getProjects();
  },
  watch: {},

  computed: {
    userType() {
      return this.$store.state.userType;
    },
  },

  methods: {
    CalculateBalance(value) {
      console.log("value-", value);
      this.totalOrgAmt = 0;
      var z = 0;
      for (let i = 0; i < value.split.length; i++) {
        z = z + value.split[i].amount;
      }
      this.totalOrgAmt = z;
    },

    editsplitdetails() {
      axios({
        method: "POST",
        url: "/setting/target/split/edit",
        headers: {
          "x-auth-token": localStorage.getItem("token"),
        },
        data: {
          id: this.curid._id,
          amount: this.curid.amount,
        },
      })
        .then((response) => {
          if (response.data.status) {
            this.showSnackBar = true;
            this.msg = response.data.msg;
            this.editsplit = false;
          } else {
            this.showSnackBar = true;
            this.msg = response.data.msg;
          }
        })
        .catch((err) => {
          this.appLoading = false;
          this.ServerError = true;
          console.log(err);
        });
    },

    deletesplitdetails() {
      axios({
        url: "/setting/target/split/delete",
        method: "POST",
        data: {
          id: this.curid._id,
        },
        headers: {
          "x-auth-token": localStorage.getItem("token"),
        },
      })
        .then((response) => {
          this.appLoading = false;
          if (response.data.status == true) {
            this.msg = response.data.msg;
            this.showSnackBar = true;
            (this.deletedialog2 = false), this.getData();
          } else {
            this.msg = response.data.msg;
            this.showSnackBar = true;
          }
        })
        .catch((err) => {
          this.appLoading = false;
          this.ServerError = true;
          console.log(err);
        });
    },

    DeleteSplit() {
      this.appLoading = true;
      axios({
        url: "delete/project/target",

        method: "post",
        headers: {
          "x-auth-token": localStorage.getItem("token"),
        },
        data: {
          id: this.splitDeleteId,
        },
      })
        .then((response) => {
          if (response.data.status) {
            this.appLoading = false;
            this.SplitDeleteDialog = false;
            this.getData();
          } else {
            this.showsnackbar = true;
            this.msg = response.data.msg;
          }
        })
        .catch((err) => {
          this.appLoading = false;
          this.ServerError = true;
          console.log(err());
        });
    },

    deleteDial(item) {
      this.splitDeleteId = item;
      this.SplitDeleteDialog = true;
    },

    getProjects() {
      this.appLoading = true;
      axios({
        url: "/projects/getalllist/fundraise",
        method: "GET",
        headers: {
          "x-auth-token": localStorage.getItem("token"),
        },
      })
        .then((response) => {
          this.projects = response.data.data;
        })
        .catch((err) => {
          this.appLoading = false;
          this.ServerError = true;
          console.log(err);
        });
    },

    /////////////////////////////////////////////////////////////////////////////////////////////////////////
    addToRaiseFund() {
      let datas = {};
      datas["id"] = this.splitID;
      datas["type"] = this.assignType;
      datas["amount"] = this.MyAmount;
      datas["note"] = this.note;
      if (this.assignType == "already received") {
        datas["alreadyraisedDate"] = this.FundApprovedDate;
      }

      if (this.assignType == "committed and confirmed") {
        datas["committedDate"] = this.FundApprovedDate;
      }
      axios({
        url: "/add/split/amount",
        method: "post",
        headers: {
          "x-auth-token": localStorage.getItem("token"),
        },
        // data: {
        //   id: this.targetData._id,
        //   type: this.assignType,
        //   amount: this.MyAmount,
        // },
        data: datas,
      })
        .then((response) => {
          // this.appLoading = false;
          if (response.data.status) {
            this.showsnackbar = true;
            this.msg = response.data.msg;
            this.spliofSplitDial = false;
            //   this.targetData= [];
            //   this.assignType = null;
            //   this.MyAmount = 0
            this.$router.push(
              "/SplitDetaildView?id=" +
                this.splitID +
                "&name=" +
                this.targetData.orgId.name
            );
          } else {
            this.showsnackbar = true;
            this.msg = response.data.msg;
            this.spliofSplitDial = false;
            this.targetData = [];
            this.assignType = null;
            this.MyAmount = 0;
            this.note = null;
          }
          //  this.$router.push("/SplitDetaildView?id =" + this.splitID )
        })
        .catch((err) => {
          this.appLoading = false;
          // this.ServerError = true;
          console.log(err);
        });
    },

    addToRaiseFund2() {
      let datas = {};
      datas["splitId"] = this.targetData2._id;
      datas["financialYear"] = this.financialYear;
      datas["amount"] = this.amount;
      axios({
        url: "/setting/target/split/add",
        method: "post",
        headers: {
          "x-auth-token": localStorage.getItem("token"),
        },
        data: datas,
      })
        .then((response) => {
          if (response.data.status) {
            this.showsnackbar = true;
            this.msg = response.data.msg;
            this.split2 = false;
            this.targetData2 = [];
            this.amount = 0;
          } else {
            this.showsnackbar = true;
            this.msg = response.data.msg;
          }
        })
        .ctargetData2atch((err) => {
          this.appLoading = false;
          this.ServerError = true;
          console.log(err);
        });
    },

    openTarget(values) {
      this.spliofSplitDial = true;
      this.targetData = values;
    },
    splitTarget(values) {
      this.split2 = true;
      this.targetData2 = values;
    },
    addInstace() {
      var temp = {
        assignedto: null,
        financialyear: null,
        orgId: null,
        amount: null,
        dateofcreation: null,
      };
      this.splitArray.push(temp);
    },
    getData() {
      this.appLoading = true;
      axios({
        url: "/split/get/details/employee",
        // url: "/project/target/assigned/employees",
        method: "post",
        headers: {
          "x-auth-token": localStorage.getItem("token"),
        },
        data: {
          empId: this.$route.query.id,
        },
      })
        .then((response) => {
          if (response.data.status) {
            this.targets = response.data.data;
            this.userData = response.data.employee;
            this.appLoading = false;
            this.totalOrg = response.data.OrgNames;
            this.splitdetails = response.data.data;
          } else {
            this.showsnackbar = true;
            this.msg = response.data.msg;
          }
        })
        .catch((err) => {
          this.appLoading = false;
          this.ServerError = true;
          console.log(err);
        });
    },

    getOrganization() {
      this.appLoading = true;
      axios({
        url: "/organization/getlist",
        method: "GET",
        headers: {
          "x-auth-token": localStorage.getItem("token"),
        },
      })
        .then((response) => {
          this.appLoading = false;
          this.organisationList = response.data.data;
        })
        .catch((err) => {
          this.appLoading = false;
          this.ServerError = true;
          console.log(err);
        });
    },

    addSplit(item) {
      {
        this.appLoading = true;
        axios({
          url: "/split/target/add",
          method: "post",
          headers: {
            "x-auth-token": localStorage.getItem("token"),
          },
          data: {
            empId: this.$route.query.id,
            orgId: this.orgId,
            targetId: item.targetid._id,
            amount: this.amount,
          },
        })
          .then((response) => {
            this.appLoading = false;
            if (response.data.status) {
              // this.userData = response.data.data;
              item.dialogSplit = false;
              this.getData();
            } else {
              this.showsnackbar = true;
              this.msg = response.data.msg;
            }
          })
          .catch((err) => {
            this.appLoading = false;
            this.ServerError = true;
            console.log(err());
          });
      }
    },
    addTarget() {
      this.appLoading = true;
      axios({
        url: "/add/target",
        method: "post",
        headers: {
          "x-auth-token": localStorage.getItem("token"),
        },
        data: {
          id: this.$route.query.id,
          totalTarget: this.totalTarget,
          financialyear: this.financialyear,
        },
      })
        .then((response) => {
          this.appLoading = false;
          if (response.data.status) {
            // this.userData = response.data.data;
            this.dialog = false;
            this.getData();
          } else {
            this.showsnackbar = true;
            this.msg = response.data.msg;
          }
        })
        .catch((err) => {
          this.appLoading = false;
          this.ServerError = true;
          console.log(err());
        });
    },
    editTarget(item) {
      this.appLoading = true;
      axios({
        url: "/add/target",
        method: "post",
        headers: {
          "x-auth-token": localStorage.getItem("token"),
        },
        data: {
          id: item._id,
          totalTarget: item.totalTarget,
          financialyear: item.fyear,
        },
      })
        .then((response) => {
          this.appLoading = false;
          if (response.data.status) {
            // this.userData = response.data.data;
            this.dialog = false;
            this.getData();
          } else {
            this.showsnackbar = true;
            this.msg = response.data.msg;
          }
        })
        .catch((err) => {
          this.appLoading = false;
          this.ServerError = true;
          console.log(err());
        });
    },
    getYear() {
      this.appLoading = true;
      axios({
        url: "/fyear/getlist",
        method: "GET",
        headers: {
          "x-auth-token": localStorage.getItem("token"),
        },
      })
        .then((response) => {
          if (response.data.status) {
            this.years = response.data.data;
            this.financialYear = response.data.data[0]._id;
          }
        })
        .catch((err) => {
          this.appLoading = false;
          this.ServerError = true;
          console.log(err);
        });
    },
    deleteTarget() {
      var data = {};
      data["id"] = this.DeleteTargetid;
      axios({
        url: "/split/target/remove",
        method: "POST",
        headers: {
          "x-auth-token": localStorage.getItem("token"),
        },
        data: data,
      })
        .then((response) => {
          this.delete = false;
          this.appLoading = false;
          if (response.data.status) {
            this.msg = "Delete Sucessfully";
            this.showsnackbar = true;
            this.DeleteTargetDialog = false;

            this.getData();
          } else {
            this.msg = response.data.msg;
            this.showsnackbar = true;
          }
        })
        .catch((err) => {
          this.appLoading = false;
          this.ServerError = true;
          console.log(err);
        });
    },
  },
};
</script>
<style>
.uLine {
  border-bottom: 3px solid #005f32;
}
.uLine2 {
  border-bottom: 3px solid green;
}
</style>
